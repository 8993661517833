import {ATTRIBUTE_TYPES} from './HitContainerAttribute';
import {useConfigurationStore} from '../../store';
import {
  Address,
  Cost,
  Country,
  DueDate,
  Equipment,
  Form,
  FormDefinitionNew,
  Journal,
  Language,
  Period,
  Project,
  ProjectPart,
  Purchase,
  PurchaseSupplier,
  Resource,
  ResourceCategory,
  Staff,
  TaxCode,
  Transaction,
  TransactionItem,
  Unit,
  CostItem
} from '../../models';
import Qualification from '../../models/hit/Qualification';
import Action from '../../models/hit/Action';

export const OPERATOR_MAPPING = {
  '>': 'gt',
  '<': 'lt',
  '≥': 'gte',
  '≤': 'lte',
  '=': 'eq',
  '≠': 'neq',
  '∋': 'ilike',
  '∌': 'not.ilike',
  '∈': 'in',
};

const TYPE_ENTITY_CLS_MAPPING = {
  90: Language,
  101: Address,
  102: Staff,
  103: Equipment,
  104: Resource,
  105: Project,
  106: ProjectPart,
  107: Transaction,
  108: Action,
  109: Form,
  110: Journal,
  111: TaxCode,
  112: DueDate,
  113: Country,
  114: Unit,
  115: ResourceCategory,
  116: Period,
  117: TransactionItem,
  118: Cost,
  119: FormDefinitionNew,
  120: Qualification,
  121: Purchase,
  122: PurchaseSupplier,
  124: CostItem
};

/**
 * Returns the base class of the entity depending on the ATTRIBUTE TYPE
 */
export function getDataTypeClass(attributeType) {
  if (attributeType < 90) {
    throw Error('Data type classes do only work for fk types');
  }
  return TYPE_ENTITY_CLS_MAPPING[attributeType];
}

export function getFkAttributesToFetch(
  joinPath,
  attributeType,
  innerPlaceholder
) {
  const entityCls = getDataTypeClass(attributeType);
  let columnString = '';
  joinPath.forEach((join) => {
    columnString += join + `${innerPlaceholder}(`;
  });
  columnString += entityCls.entityColumns(
    entityCls.getAttributes(entityCls.joinAttrsKey)
  );
  columnString = columnString + ')'.repeat(joinPath.length);
  return columnString;
}

export function parseFkEntity(data, attributeType) {
  if (!data) return null;
  const entityCls = getDataTypeClass(attributeType);
  return new entityCls(data);
}

export function getFilterName(attribute, key) {
  if (attribute.dataType > 100) {
    return `${key}.and`;
  } else if (attribute.dataType === ATTRIBUTE_TYPES.TAGS) {
    return `${key}Filter.or`;
  } else {
    return 'and';
  }
}

export function getJoinedAttributeSyntax(joinDefinition, addInner) {
  if ('column' in joinDefinition) {
    if (typeof joinDefinition['column'] === 'string') {
      if ('selectColumns' in joinDefinition) {
        return addInner
          ? `${joinDefinition['table']}!inner(${joinDefinition['selectColumns']})`
          : `${joinDefinition['table']}(${joinDefinition['selectColumns']})`;
      }
      return addInner
        ? `${joinDefinition['table']}!inner(${joinDefinition['column']})`
        : `${joinDefinition['table']}(${joinDefinition['column']})`;
    } else {
      const inner = getJoinedAttributeSyntax(joinDefinition['column']);
      return addInner
        ? `${joinDefinition['table']}!inner(${inner})`
        : `${joinDefinition['table']}(${inner})`;
    }
  }
  return joinDefinition['column'];
}

export function getColumnNames(attribute, attributeKey) {
  if (attribute.dataType > 100) {
    return attribute.dataObjectClass.joinFilterColumns;
  }
  switch (attribute.dataType) {
    case ATTRIBUTE_TYPES.TAGS:
      return [attribute.column.column];
    case ATTRIBUTE_TYPES.ML_WITH_FALLBACK:
      return [
        (attribute.column || attributeKey).replaceAll(
          'LOCALE',
          useConfigurationStore().userLanguageSnakeCase
        ),
        (attribute.column || attributeKey).replaceAll(
          'LOCALE',
          useConfigurationStore().mainLanguageSnakeCase
        ),
      ];
    default:
      return [attribute.column || attributeKey];
  }
}

export function getFkFilterValue(columns, filterValue) {
  let filters = [];
  filterValue.forEach((val) => {
    let valuePart = 'or(';
    columns.forEach((c) => {
      valuePart += `${c}.ilike.*${val}*,`;
    });
    valuePart = valuePart.slice(0, -1) + ')';
    filters.push(valuePart);
  });
  return filters;
}

export function queryForComputedColumns(columns, filters) {
  const subQueries = [];
  Object.entries(filters).forEach(([filter, fValues]) => {
    if (Array.isArray(fValues)) {
      fValues.forEach((entireValue) => {
        let valueQuery = '';
        entireValue
          .split(' ')
          .filter((i) => i !== '')
          .forEach((value) => {
            columns.forEach((column) => {
              valueQuery += `${column}.${OPERATOR_MAPPING[filter]}.*${value}*,`;
            });
          });
        subQueries.push(`or(${valueQuery.slice(0, -1)})`);
      });
    } else {
      fValues
        .split(' ')
        .filter((i) => i !== ' ')
        .forEach((value) => {
          let valueQuery = '';
          columns.forEach((column) => {
            valueQuery += `${column}.${OPERATOR_MAPPING[filter]}.${value},`;
          });
          subQueries.push(`or(${valueQuery.slice(0, -1)})`);
        });
    }
  });
  return subQueries.join(',');
}

export function mergeFilters(arrayOfFilterObjects) {
  const mergedFilters = {};
  arrayOfFilterObjects.forEach((filters) => {
    if (!filters) return;
    Object.entries(filters).forEach(([filter, values]) => {
      if (!(filter in mergedFilters)) mergedFilters[filter] = [];
      mergedFilters[filter] = mergedFilters[filter].concat(values);
    });
  });
  return mergedFilters;
}
