<template>
  <div
    class="fixed inset-0 overflow-y-auto flex items-center justify-center z-30"
  >
    <div
      class="modal-overlay fixed inset-0 bg-black opacity-50"
      @click="clickOutside"
    />
    <div
      :id="modalDynamicName"
      class="modal-container flex flex-col bg-panel rounded-lg shadow-lg z-10 overflow-auto"
      :class="{
        'overflow-auto': overflow,
        'overflow-hidden': !overflow,
        'h-full': fullHeight,
        'fixed top-2': !responsiveBreakpointHeightMd,
        relative: responsiveBreakpointHeightMd,
      }"
      :style="{'max-width': '95%', 'max-height': '90%', ...containerWidth}"
    >
      <slot name="title">
        <div
          v-if="prefixIcon || title"
          class="flex flex-row space-x-4 text-lg text-panel pt-6 pl-4 pr-10 lg:pr-4 lg:pt-4"
        >
          <hit-icon
            v-if="prefixIcon"
            :icon="prefixIcon"
          />
          <span v-if="title">{{ title }}</span>
        </div>
      </slot>
      <div
        class="modal-content flex-grow"
        :class="{
          'large-enough-for-form': responsiveBreakpointXs,
          'p-4': !noPadding,
        }"
      >
        <div
          v-if="cancellable"
          class="absolute top-2 right-2"
        >
          <hit-icon
            v-if="!delayCancelWithSpinner"
            icon="clear"
            :clickable="true"
            class="focus:outline-none focus:font-light transition ease-in-out duration-150"
            color="text-panel"
            @click="onCancel"
          />
          <hit-spinner v-else />
        </div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import {HitIcon} from '../icon';
import {computed, onMounted, provide, ref} from 'vue';
import {HitBreakpointsMixin} from '../../mixins';
import HitSpinner from '../../components/icon/HitSpinner.vue';
import breakpoints from '../../utils/breakpoints/breakpoints';
import {HitUUIDUtils} from '../../utils';

export default {
  name: 'HitModal',
  components: {
    HitIcon,
    HitSpinner,
  },
  mixins: [HitBreakpointsMixin],
  props: {
    modalId: {
      type: String,
      required: false,
      default: null,
    },
    popupSize: {
      type: String,
      required: false,
      default: 'normal',
      validator: function (value) {
        return ['small', 'normal', 'large'].indexOf(value) !== -1;
      },
    },

    /**
     * Title displayed on top of the modal
     */
    title: {
      type: String,
      required: false,
      default: null,
    },

    /**
     * Icon displayed on the left of the title when the title is defined
     */
    prefixIcon: {
      type: String,
      required: false,
      default: null,
    },
    disableClickOutside: {
      type: Boolean,
      required: false,
      default: false,
    },
    cancellable: {
      type: Boolean,
      required: false,
      default: false,
    },
    delayCancelWithSpinner: {
      type: Boolean,
      required: false,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    fullHeight: {
      type: Boolean,
      required: false,
      default: false,
    },
    overflow: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup() {
    const modalDynamicName = `modal-content-container${HitUUIDUtils.generate()}`;
    const modalWidth = ref(0);

    onMounted(() => {
      const modalElement = document.getElementById(modalDynamicName);
      if (modalElement) {
        modalWidth.value = modalElement.getBoundingClientRect().width;
      }
    });

    const largeEnoughForGrid = computed(() => {
      return modalWidth.value > 768;
    });

    provide('largeEnoughForGrid', largeEnoughForGrid);

    return {modalWidth, largeEnoughForGrid, modalDynamicName};
  },
  computed: {
    /**
     * Sets the width of the modal if prop defined in parent component
     * Limits the modal size to max-w-3xl if the property is not defined in parent component
     * @returns {{width: string}|{"max-width": string}}
     */
    containerWidth() {
      if (this.popupSize === 'small') {
        if (!this.responsiveBreakpointSm) {
          return {width: '95%'};
        } else if (this.responsiveBreakpointLg) {
          return {width: '25%'};
        } else {
          return {width: '50%'};
        }
      } else if (this.popupSize === 'large') {
        {
          return {width: '85%'};
        }
      } else {
        if (!this.responsiveBreakpointMd) {
          return {width: '95%'};
        } else {
          return {width: '50%'};
        }
      }
    },
  },
  beforeMount() {
    provide('modalId', this.modalId);
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    clickOutside() {
      if (!this.disableClickOutside && !this.delayCancelWithSpinner) {
        this.$emit('clickOutside');
      }
    },
  },
};
</script>

<style>
.modal-overlay {
  touch-action: none;
}
</style>
