<template>
  <hit-base-input
    :label="label"
    :validation-state="validationState"
    :inline-input="inlineInput"
    :inline-label-width="inlineLabelWidth"
    :inline-label-color-visible="inlineLabelColorVisible"
    :reset-validation-if-valid="resetValidationIfValid"
    :full-width="false"
    :show-errors="enableErrors"
  >
    <div
      :style="inputStyle"
      :class="{
        'grid input': inlineInput,
        'gap-1': extraSlotVisible,
        'items-center inline-grid':
          ((isDate || isDateTime) && disabled) || isCheckbox,
      }"
      class="h-full"
    >
      <input
        v-if="!((isDate || isDateTime) && disabled)"
        ref="input"
        class="bg-input"
        max="9999-12-31"
        :class="{
          'border-input focus:border-input-focus':
            (valid && pristine) ||
            !validationState ||
            (valid && dirty) ||
            !enableErrors,
          'border-danger focus:border-danger': invalid && dirty && enableErrors,
          'lg:w-auto': inlineInput && !isCheckbox && !(isDate || isDateTime),
          [widthClass]: widthClass !== null,
          'w-full': !widthClass && !isCheckbox && !(isDate || isDateTime),
          'w-30': isDate,
          'w-52': isDateTime && !showSeconds,
          'w-56': isDateTime && showSeconds,
          'border focus:ring-transparent rounded h-full focus:outline-none': !isCheckbox,
          'mt-1': isCheckbox,
          'ml-1': isCheckbox && label,
          'bg-gray-200': readonly,
          [textAlignment]: textAlignment,
        }"
        :value="inputValue"
        :type="inputType"
        :step="step"
        :disabled="disabled"
        :readonly="readonly"
        :placeholder="placeholder"
        @input="onInput"
        @change="onChange"
        @focus="onFocus"
        @keyup.enter="(evt) => $emit('keyup.enter', evt.target.value)"
        @blur="(evt) => $emit('blur', evt.target.value)"
      >
      <hit-date
        v-else
        :value="inputValue"
        :show-only-date="isDate"
      />
      <hit-icon
        v-if="copyable"
        icon="copy"
        clickable
        @click="copyToClipboard"
      />
      <hit-icon
        v-if="showTogglePassword"
        :icon="inputType === 'password' ? 'show-on' : 'show-off'"
        clickable
        @click="onPasswordVisibilitySwitch"
      />
      <!-- @slot Slot to add extra code on the right of the input -->
      <slot name="extra" />
    </div>
    <template
      v-for="({}, slot) of $slots"
      #[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>
  </hit-base-input>
</template>

<script>
import HitBaseInput from './HitBaseInput';
import HitFormValidationMixin from '../../../mixins/form/HitFormValidationMixin';
import HitInputMixin from '../../../mixins/form/HitInputMixin';
import {useI18n} from 'vue-i18n';
import HitDate from '../../date/HitDate.vue';
import {HitIcon} from '../../icon';
import {HitBreakpointsMixin} from '../../../mixins';

export default {
  name: 'HitInput',
  components: {HitIcon, HitBaseInput, HitDate},
  mixins: [HitFormValidationMixin, HitInputMixin, HitBreakpointsMixin],
  props: {
    /**
     * Type of the input
     */
    type: {
      type: String,
      default: 'text',
    },
    /**
     * Value of the input
     */
    value: {
      type: [String, Date, Number],
      required: false,
      default: null,
    },
    /**
     * Disable the input
     */
    disabled: Boolean,
    /**
     * Placeholder of the input
     */
    placeholder: {
      type: String,
      default: null,
    },
    /**
     * Size of the extra slot
     */
    extraSlotSize: {
      type: String,
      default: 'max-content',
    },
    textAlignment: {
      type: String,
      required: false,
      default: null,
    },
    widthClass: {
      type: String,
      required: false,
      default: null,
    },
    /** Set to true to focus the input when the page finished loading **/
    instantFocus: {
      type: Boolean,
      default: false,
    },
    copyable: {
      type: Boolean,
      default: false,
      required: false,
    },
    showTogglePassword: {
      type: Boolean,
      default: false,
      required: false,
    },
    resetAfterChange: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Set step to 1 second for datetime inputs (instead of 1 minute)
     */
    showSeconds: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * When set to true, the entire content is selected when focussing the input
     */
    selectEntireContentOnFocus: {
      type: Boolean,
      required: false,
      default: false,
    },
    enableErrors: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup() {
    const {t} = useI18n();
    let passwordIsVisible = false;
    return {t, passwordIsVisible};
  },
  computed: {
    isDate() {
      return this.type === 'date';
    },
    isDateTime() {
      return this.type === 'datetime-local';
    },
    isTime() {
      return this.type === 'time';
    },
    step() {
      if (this.isDateTime || this.isTime) {
        return this.showSeconds ? 1 : 60;
      } else {
        return 1;
      }
    },
    isText() {
      return this.type === 'text';
    },
    isCheckbox() {
      return this.type === 'checkbox';
    },
    inputValue() {
      if (this.value || this.value === 0) {
        if (this.isDate && this.value instanceof Date) {
          return this.value.toISOString().split('T')[0];
        }
        if (this.isDateTime && this.value instanceof Date) {
          const date = new Date(
            this.value.getTime() - this.value.getTimezoneOffset() * 60000
          ).toISOString();
          return this.showSeconds ? date.slice(0, 19) : date.slice(0, 16);
        }
        return this.value;
      }
      return '';
    },
    inputType() {
      if (this.type === 'int') {
        return 'number';
      } else {
        return this.type;
      }
    },
    inputStyle() {
      return {
        '--input-extra-slot-width': this.extraSlotSize,
      };
    },
    extraSlotVisible() {
      return !!this.$slots['extra'];
    },
  },
  mounted() {
    if (this.instantFocus && this.responsiveBreakpointHeightXl) {
      this.$refs.input.focus();
    }
  },
  methods: {
    onInput: function ($event) {
      this.fireInputInput(this.valueToReturn($event.target.value));
      $event.stopImmediatePropagation();
    },
    onChange($event) {
      this.fireInputChange(this.valueToReturn($event.target.value));
      if (this.resetAfterChange) {
        $event.target.value = null;
      }
      $event.stopImmediatePropagation();
    },
    onPasswordVisibilitySwitch() {
      this.passwordIsVisible = !this.passwordIsVisible;
      this.$emit('passwordVisibilityChange', this.passwordIsVisible);
    },
    onFocus($event) {
      /**
       * When the input is has the focus
       */
      if (this.selectEntireContentOnFocus) {
        this.$refs.input.select();
      }
      this.$emit('focus');
      $event.stopImmediatePropagation();
      if (this.validationState) {
        this.validationState.$touch();
      }
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.value);
    },
    valueToReturn(value) {
      if (value === '') {
        return this.isDate || this.isDateTime ? 'EMPTY_DATE' : null;
      } else {
        if (this.type === 'number') {
          value = parseFloat(value);
        } else if (this.type === 'int') {
          // Still parse as float to allow validations
          if (value.includes('.')) {
            value = parseFloat(value);
          } else {
            value = parseInt(value);
          }
        } else if (this.type === 'datetime-local') {
          return new Date(value);
        }
        return value;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.input {
  grid-template-columns: minmax(0, 1fr) var(--input-extra-slot-width);
}

[type='text'],
[type='email'],
[type='url'],
[type='password'],
[type='number'],
[type='date'],
[type='datetime-local'],
[type='month'],
[type='search'],
[type='tel'],
[type='time'],
[type='week'] {
  font-size: inherit;
  padding-right: 7px;
  padding-left: 7px;
  @apply py-0 text-input;
}
</style>
