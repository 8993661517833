import {Item, Transaction} from './index';
import {ATTRIBUTE_TYPES, HitContainerAttribute} from '../../components';

export default class TransactionItem extends Item {
  constructor({
    id,
    number,
    designation,
    description = undefined,
    parent = undefined,
    active = true,
    option = undefined,
    itemType = undefined,
    titleLevel = undefined,
    resource = undefined,
    adminType = undefined,
    quantity = undefined,
    unit = undefined,
    discount = undefined,
    taxCode = undefined,
    comment = undefined,
    calculated = false,
    transaction = undefined,
    netPrice = undefined,
    netAmount = undefined,
    grossPrice = undefined,
    grossAmount = undefined,
    salesPrice = undefined,
    salesAmount = undefined,
    cost  = undefined,
  }) {
    super(
      id,
      number,
      designation,
      description,
      parent,
      active,
      option,
      itemType,
      titleLevel,
      resource,
      adminType,
      quantity,
      unit,
      discount,
      taxCode,
      comment,
      calculated,
    );
    this.transaction = transaction ? new Transaction(transaction) : null;
    this.netPrice = netPrice;
    this.netAmount = netAmount;
    this.grossPrice = grossPrice;
    this.grossAmount = grossAmount;
    this.salesPrice = salesPrice;
    this.salesAmount = salesAmount;
    this.cost = cost
  }

  static get fkName() {
    return 'transaction_item_id';
  }
  static get apiRoute() {
    return 'transaction_item';
  }
  static get detailPageRoute() {
    return 'transactionDetail';
  }
  static get listPageRoute() {
    return 'transactionDetail';
  }
  get fkName() {
    return TransactionItem.fkName;
  }
  get apiRoute() {
    return TransactionItem.apiRoute;
  }
  get listPageRoute() {
    return TransactionItem.listPageRoute;
  }
  get detailPageRoute() {
    return TransactionItem.detailPageRoute;
  }

  static get listDisplayAttrs() {
    return this.getAttributes(
      [
        'number',
        'designation',
        'unit',
        'adminType',
        'quantity',
        'grossPrice',
        'discount',
        'salesPrice',
        'salesAmount',
        'taxCode',
        'comment',
        'cost',
      ],
      ['itemType', 'titleLevel', 'resource', 'description', 'calculated']
    );
  }

  static get allAttributes() {
    const baseAttributes = Item.allAttributes;
    baseAttributes.unit.joinPath = ['unit!fk_transaction_item_unit_id_unit'];
    baseAttributes.taxCode.joinPath = [
      'tax_code!fk_transaction_item_tax_code_id_tax_code',
    ];
    baseAttributes.resource.joinPath = [
      'resource!fk_transaction_item_resource_id_resource',
    ];

    return {
      ...baseAttributes,
      transaction: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_TRANSACTION, {
        column: 'transaction_id',
        joinPath: [
          'transaction!fk_transaction_item_transaction_id_transaction',
        ],
        tableProperties: {
          header: 'hit-app.common.transaction',
        },
      }),
      netPrice: new HitContainerAttribute(ATTRIBUTE_TYPES.DECIMAL, {
        column: 'net_price',
        decimalPrecision: 2,
        decimalDefaultValue: 0.0,
        tableProperties: {
          header: 'hit-app.common.net-price',
          maxWidth: '0.5fr',
        },
      }),
      netAmount: new HitContainerAttribute(ATTRIBUTE_TYPES.DECIMAL, {
        decimalPrecision: 2,
        column: 'net_amount',
        readOnly: true,
        default: 0.0,
        tableProperties: {
          header: 'hit-app.common.net-amount',
          maxWidth: '0.65fr',
        },
      }),
      grossPrice: new HitContainerAttribute(ATTRIBUTE_TYPES.DECIMAL, {
        decimalPrecision: 2,
        column: 'gross_price',
        default: 0.0,
        tableProperties: {
          header: 'hit-app.common.gross-price',
          maxWidth: '0.5fr',
        },
      }),
      grossAmount: new HitContainerAttribute(ATTRIBUTE_TYPES.DECIMAL, {
        decimalPrecision: 2,
        column: 'gross_amount',
        default: 0.0,
        readOnly: true,
        tableProperties: {
          header: 'hit-app.common.gross-amount',
          maxWidth: '0.65fr',
        },
      }),
      salesPrice: new HitContainerAttribute(ATTRIBUTE_TYPES.DECIMAL, {
        decimalPrecision: 2,
        column: 'sales_price',
        default: 0.0,
        readOnly: true,
        tableProperties: {
          header: 'hit-app.common.sales-price',
          maxWidth: '0.5fr',
          readOnly: true,
        },
      }),
      salesAmount: new HitContainerAttribute(ATTRIBUTE_TYPES.DECIMAL, {
        decimalPrecision: 2,
        column: 'sales_amount',
        default: 0.0,
        readOnly: true,
        tableProperties: {
          header: 'hit-app.common.sales-amount',
          maxWidth: '0.65fr',
          readOnly: true,
        },
      }),
      cost: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_COST_ITEM, {
        column:'cost_data_id',
        joinPath: [
          'cost_data!fk_transaction_item_cost_data_id_cost_data',
        ],
        tableProperties: {
          header: 'hit-app.common.cost-forwarded',
          readOnly: true,
        },
      }),
    };
  }
}
