<template>
  <div
    class="flex gap-1 flex-1"
    :class="{
      'flex-1': fullWidth,
      'flex-row': flexDirection === 'horizontal',
      'flex-col': flexDirection === 'vertical',
      'flex-wrap': !useMinimumHeight,
    }"
  >
    <div
      v-for="item in entitiesToDisplay"
      :key="item.designation"
      ref="draggableItems"
      class="font-medium items-center overflow-x-hidden relative"
      :class="{
        'w-full': fullWidth,
      }"
      @mouseenter="($event) => displayTooltip($event, item)"
      @mouseleave="hideTooltip"
      @click.stop="handleClickIfEmptyDesignation(item)"
    >
      <div
        class="flex-row flex bg-white rounded-lg"
        :class="{
          'h-6': useMinimumHeight,
          'min-h-6': !useMinimumHeight,
        }"
        :style="generateCssBorderStyle(item)"
      >
        <div
          class="text-xs font-normal leading-none max-w-full leading-snug flex-1 flex py-1 pl-2 rounded-l-md"
          :class="{
            'flex-row items-center justify-items': centerContent,
            'items-start flex-col': !centerContent,
          }"
          :style="generateCssStyle(item)"
        >
          <slot
            name="prefix-icon"
            :item="item"
          >
            <div
              class="cursor-grab"
              @mousedown="$emit('startGrab', item)"
            >
              <hit-icon
                v-if="draggable"
                icon="drag"
                size="xs"
              />
            </div>
          </slot>
          <div
            :class="{
              'px-1 text-xs font-normal leading-none max-w-full grid grid-flow-col gap-2': !spacyLayout,
            }"
          >
            <span
              :class="{
                'hover:underline cursor-pointer': redirectRouteDataFn,
                'whitespace-pre-wrap': !useMinimumHeight,
              }"
              @click.stop="handleClick(item)"
              @mousedown.middle.stop="handleMouseDownMiddle(item)"
            >
              {{ item.designation }}
            </span>
          </div>
          <div>
            <slot
              name="additionalLine"
              :item="item"
            />
          </div>
        </div>
        <div
          class="flex flex-col items-start px-1 py-1 rounded-r-md"
          :style="generateCssStyleIcons(item)"
        >
          <hit-icon
            v-if="clearable"
            icon="clear"
            clickable
            @click="$emit('remove', item.id)"
          />
          <hit-icon
            v-for="(icon, index) in customIcons"
            :key="index"
            :icon="icon.icon"
            :size="customIconSize"
            clickable
            @click="$emit(icon.event, item)"
          />
        </div>
      </div>
    </div>
  </div>
  <Teleport
    v-if="tooltipItem"
    to="#root-element"
  >
    <div
      v-if="tooltipItem"
      class="absolute bg-header text-header text-xs rounded-lg p-2 max-w-64"
      :style="tooltipPositionStyle"
    >
      <slot
        name="tooltip"
        :item="tooltipItem"
      >
        {{ tooltipItem?.designation }}
      </slot>
    </div>
  </Teleport>
</template>

<script>
import {HitIcon} from '../icon';
import {ColourUtils} from '../../utils/colour';

export default {
  name: 'HitEntityList',
  components: {
    HitIcon,
  },
  props: {
    /**
     * Array with the different entities to display. Each entity has an object with:
     *    - id: id used to identify the record
     *    - designation: designation that will be displayed
     */
    entitiesToDisplay: {
      type: Array,
      required: true,
    },

    /**
     * Boolean to activate the display of the cancel icon behind each record
     */
    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },

    /**
     * Array of custom icons that are displayed at the end of the entity list
     */
    customIcons: {
      type: Array,
      required: false,
      default: null,
    },

    /**
     * Use a specific icon size for the custom icon
     */
    customIconSize: {
      type: String,
      required: false,
      default: 'xs',
    },

    /**
     * We need to pass a function that returns the name of the detail page we
     * want to redirect to because we can have entities of different types in the
     * same list
     */
    redirectRouteDataFn: {
      type: Function,
      required: false,
      default: null,
    },

    /**
     * Flag to make the entity list full width of the parent element
     */
    fullWidth: {
      type: Boolean,
      required: false,
      default: false,
    },

    /**
     * In which direction the list should be flexed
     */
    flexDirection: {
      type: String,
      required: false,
      default: 'horizontal',
      validator: (value) => {
        return ['horizontal', 'vertical'].indexOf(value) !== -1;
      },
    },

    /**
     * When used with more text, we can activate this layout to increase the readibility
     */
    spacyLayout: {
      type: Boolean,
      required: false,
      default: false,
    },

    /**
     * If set to true, aligns the content vertically in the middle, otherwise: items-start
     */
    centerContent: {
      type: Boolean,
      required: false,
      default: true,
    },

    /**
     * Displays the entities on one line with no wrapping
     */
    useMinimumHeight: {
      type: Boolean,
      required: false,
      default: true,
    },

    /**
     * Creates a contrast between the content and the icon bar
     */
    applyContrast: {
      type: Boolean,
      required: false,
      default: false,
    },

    /**
     * Adapts the styling to make each record draggable
     */
    draggable: {
      type: Boolean,
      required: false,
      default: false,
    },

    activateTooltip: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      tooltipItem: null,
      isGrabbing: false,
      mouseX: 0,
      mouseY: 0,
    };
  },
  computed: {
    screenHeight() {
      return window.innerHeight;
    },
    screenWidth() {
      return window.innerWidth;
    },

    emitsList() {
      let emits = this.customIcons.map((icon) => icon.event);
      emits = emits.concat(['remove', 'startGrab']);
      return emits;
    },

    tooltipPositionStyle() {
      const offset = 15;
      const horizontal =
        this.mouseX > this.screenWidth / 2
          ? {right: `${this.screenWidth - (this.mouseX - offset)}px`}
          : {left: `${this.mouseX + offset}px`};
      const vertical =
        this.mouseY > this.screenHeight / 2
          ? {bottom: `${this.screenHeight - (this.mouseY - offset)}px`}
          : {top: `${this.mouseY + offset}px`};
      return {
        position: 'absolute',
        ...horizontal,
        ...vertical,
      };
    },
  },
  methods: {
    displayTooltip(event, item) {
      if (!this.activateTooltip) return;
      this.mouseX = event.clientX;
      this.mouseY = event.clientY;
      this.tooltipItem = item;
    },

    hideTooltip() {
      this.tooltipItem = null;
    },

    handleClick(item) {
      this.redirectTo(item);
    },

    /**
     * If the item has no designation, we also redirect if only clicking into the box
     */
    handleClickIfEmptyDesignation(item) {
      if (item?.designation) return;
      this.redirectTo(item);
    },

    /**
     * Calls the redirect function if it has been passed to the component
     */
    redirectTo(item) {
      if (this.redirectRouteDataFn) {
        const routeData = this.redirectRouteDataFn(item);
        this.$router.push(routeData);
      }
    },

    handleMouseDownMiddle(item) {
      if (this.redirectRouteDataFn) {
        const routeData = this.redirectRouteDataFn(item);
        const a = document.createElement('a');
        a.href = routeData.fullPath;
        a.target = '_blank';
        const e = new MouseEvent('click', {
          ctrlKey: true,
          metaKey: true,
        });
        a.dispatchEvent(e);
      }
    },

    generateCssStyleIcons(tag) {
      return {
        'background-color': tag?.colour ? `#${tag.colour}` : 'var(--color-tag)',
        opacity: 1,
        color: `${
          tag.colour
            ? ColourUtils.getFontColorOnBg(tag.colour)
            : 'var(--text-tag)'
        }`,
      };
    },

    generateCssStyle(tag) {
      let opacity = 1;
      if (this.applyContrast) opacity = 0.75;
      return {
        'background-color': tag?.colour ? `#${tag.colour}` : 'var(--color-tag)',
        opacity: opacity,
        color: `${
          tag.colour
            ? ColourUtils.getFontColorOnBg(tag.colour, opacity)
            : 'var(--text-tag)'
        }`,
      };
    },

    generateCssBorderStyle(tag) {
      if (!tag?.borderColour) return {};
      return {
        border: `2px solid #${tag.borderColour}`,
        'padding-top': '-1px',
        'padding-bottom': '-1px',
      };
    },
  },

  emits() {
    return this.emitsList;
  },
};
</script>

<style scoped>
.tooltip {
  transition: top 0.1s ease, left 0.1s ease;
}
</style>
