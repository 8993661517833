import BaseModel from './BaseModel';
import {ATTRIBUTE_TYPES, HitContainerAttribute} from '../../components';
import {Address, Project} from './index';
import {HitUtils} from '@hit/components/src/utils/hit/HitUtils';
import {required} from '@vuelidate/validators';
import {HitOnlineEnums} from 'hit-online-web-ui/src/helpers/constants';
import {translate} from '../../plugins/i18n/i18n';

export default class ProjectPart extends BaseModel {
  constructor({
    id,
    number,
    designation,
    active = true,
    status = undefined,
    customer = undefined,
    project = undefined,
    tags = [],
    planning = false,
    isQuotation = false,
    isWork = false,
    memo = undefined,
    header,
    description,
    footer,
    // We also need to add the key how it is stored in the db (we use the db column names)
    no = undefined,
  }) {
    super(id, number || no, designation);
    this.active = active;
    this.status = status
      ? HitOnlineEnums.getEnumValue('PROJECT_PART_STATUS', status, translate)
      : null;
    this.customer = customer ? new Address(customer) : undefined;
    this.project = project ? new Project(project) : undefined;
    this.tags = tags;
    this.planning = planning;
    this.isQuotation = isQuotation;
    this.isWork = isWork;
    this.memo = memo;
    this.header = header;
    this.description = description;
    this.footer = footer;
  }

  static get printTemplateType() {
    return 'projectPart';
  }
  get printTemplateType() {
    return ProjectPart.printTemplateType;
  }

  static get config() {
    const config = BaseModel.config;
    config['importable'] = false;
    config['addable'] = false;
    return config;
  }

  get config() {
    const config = BaseModel.config;
    config['importable'] = false;
    return config;
  }

  static get fkName() {
    return 'project_part_id';
  }
  static get apiRoute() {
    return 'project_part';
  }
  static get detailPageRoute() {
    return 'projectPartDetail';
  }
  static get listPageRoute() {
    return 'projectDetail';
  }
  get fkName() {
    return ProjectPart.fkName;
  }
  get apiRoute() {
    return ProjectPart.apiRoute;
  }
  get listPageRoute() {
    return ProjectPart.listPageRoute;
  }
  get detailPageRoute() {
    return ProjectPart.detailPageRoute;
  }
  static get attrsToStoreInForm() {
    return ['number', 'designation', 'project'];
  }
  get attrsToStoreInForm() {
    return BaseModel.attrsToStoreInForm;
  }

  static get entityListDisplayAttrs() {
    return this.getAttributes([
      'number',
      'designation',
      'active',
      'status',
      'customer',
      'amount',
      'tags',
    ]);
  }

  static get joinAttrsKey() {
    return [
      'number',
      'designation',
      'customer',
      'project',
      'isQuotation',
      'isWork',
    ];
  }

  get fullDesignation() {
    return HitUtils.createStandardDesignation(
      {
        project: HitUtils.createStandardDesignation(
          this.project,
          'number',
          'designation',
          '.'
        ),
        projectPart: HitUtils.createStandardDesignation(
          this,
          'number',
          'designation',
          '.'
        ),
      },
      'project',
      'projectPart'
    );
  }

  get entityDesignation() {
    return HitUtils.createStandardDesignation(this);
  }

  get createInitColumns() {
    return {
      designation: '',
      project_id: this.project.id,
    };
  }

  static get browserDisplayAttrs() {
    return this.getAttributes([
      'project',
      'number',
      'designation',
      'active',
      'status',
      'tags',
    ]);
  }

  static get positionInformationAttrs() {
    return this.getAttributes(
      [],
      ['project', 'number', 'designation', 'customer']
    );
  }

  static get allAttributes() {
    return {
      number: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'no',
        tableProperties: {
          header: 'hit-app.common.number',
          maxWidth: '1fr',
        },
      }),
      designation: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        validations: {required},
        tableProperties: {
          header: 'hit-app.common.designation',
          maxWidth: '1.5fr',
        },
      }),
      active: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        tableProperties: {
          propertyName: 'active',
          header: 'hit-app.common.active',
          maxWidth: '4rem',
        },
      }),
      status: new HitContainerAttribute(ATTRIBUTE_TYPES.ENUMERATION, {
        enumType: 'PROJECT_PART_STATUS',
        tableProperties: {
          propertyName: 'status',
          header: 'hit-app.common.status',
          maxWidth: '1.5fr',
        },
      }),
      customer: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_ADDRESS, {
        joinPath: ['address!fk_project_part_address_id_address'],
        tableProperties: {
          header: 'hit-app.common.customer',
          maxWidth: '1fr',
        },
      }),
      project: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_PROJECT, {
        joinPath: ['project!fk_project_part_project_id_project'],
        tableProperties: {
          header: 'hit-base.common.project',
        },
      }),
      amount: new HitContainerAttribute(ATTRIBUTE_TYPES.DECIMAL, {
        column: 'project_part_item(sales_amount.sum())',
        tableProperties: {
          header: 'hit-app.common.amount',
        },
        transform: (res) => res[0].sum,
        decimalPrecision: 2,
      }),

      tags: new HitContainerAttribute(ATTRIBUTE_TYPES.TAGS, {
        tableProperties: {
          header: 'hit-base.common.tags',
          maxWidth: '1fr',
        },
      }),
      planning: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        tableProperties: {
          header: 'hit-base.common.planning',
        },
      }),
      isQuotation: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        column: 'is_quotation',
        tableProperties: {
          header: 'hit-app.project.quotation-part',
        },
      }),
      isWork: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        column: 'is_work',
        tableProperties: {
          header: 'hit-app.project.work-part',
        },
      }),
      memo: new HitContainerAttribute(ATTRIBUTE_TYPES.RICH_TEXT, {
        tableProperties: {
          header: 'hit-base.common.memo',
        },
      }),
      header: new HitContainerAttribute(ATTRIBUTE_TYPES.RICH_TEXT, {
        column: 'header',
        tableProperties: {
          header: 'hit-app.common.header',
          maxWidth: '1fr',
        },
      }),
      description: new HitContainerAttribute(ATTRIBUTE_TYPES.RICH_TEXT, {
        column: 'description',
        tableProperties: {
          header: 'hit-base.common.description',
          maxWidth: '1fr',
        },
      }),
      footer: new HitContainerAttribute(ATTRIBUTE_TYPES.RICH_TEXT, {
        column: 'footer',
        tableProperties: {
          header: 'hit-app.common.footer',
          maxWidth: '1fr',
        },
      }),
    };
  }

  redirectToList(router) {
    router.push({
      name: 'projectDetail',
      params: {
        id: this.project?.id ?? 0,
      },
    });
  }
}
