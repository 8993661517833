import {BaseModel, Equipment, ProjectPart} from './index';
import {ATTRIBUTE_TYPES, HitContainerAttribute} from '../../components';
import {required} from '@vuelidate/validators';

export default class Cost extends BaseModel {
  constructor({
    id,
    number,
    designation,
    active = true,
    memo = undefined,
    costDate = undefined,
    supplier = undefined,
    projectPart = undefined,
    equipment = undefined,
  }) {
    super(id, number, designation);
    this.active = active;
    this.memo = memo;
    this.costDate = costDate;
    this.supplier = supplier;
    this.projectPart = projectPart ? new ProjectPart(projectPart) : null;
    this.equipment = equipment ? new Equipment(equipment) : null;
  }

  static get joinAttrsKey() {
    return ['number', 'designation', 'projectPart', 'equipment'];
  }
  static get fkName() {
    return 'cost_id';
  }
  static get apiRoute() {
    return 'cost';
  }
  static get detailPageRoute() {
    return 'costsView';
  }
  static get listPageRoute() {
    return 'costsView';
  }
  get fkName() {
    return Cost.fkName;
  }
  get apiRoute() {
    return Cost.apiRoute;
  }
  get listPageRoute() {
    return Cost.listPageRoute;
  }
  get detailPageRoute() {
    return Cost.detailPageRoute;
  }

  static get browserDisplayAttrs() {
    return this.getAttributes(['number', 'designation', 'active', 'costDate']);
  }

  static get entityListDisplayAttrs() {
    return this.getAttributes(
      [
        'number',
        'designation',
        'active',
        'supplier',
        'costDate',
        'totalAmount',
      ],
      ['projectPart', 'staff', 'equipment', 'form', 'injectedFromForm']
    );
  }

  static get allAttributes() {
    return {
      number: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'no',
        tableProperties: {
          header: 'hit-app.common.number',
          maxWidth: '1fr',
        },
      }),
      designation: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        validations: {required},
        tableProperties: {
          header: 'hit-app.common.designation',
          maxWidth: '2fr',
        },
      }),
      active: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        creationValue: true,
        tableProperties: {
          header: 'hit-app.common.active',
          maxWidth: '0.5fr',
          hideByDefault: true,
        },
      }),
      memo: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        tableProperties: {
          header: 'hit-base.common.memo',
        },
      }),
      costDate: new HitContainerAttribute(ATTRIBUTE_TYPES.DATE, {
        column: 'cost_date',
        tableProperties: {
          header: 'hit-base.common.cost-date',
        },
      }),
      supplier: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_ADDRESS, {
        column: 'address_id',
        joinPath: ['address!fk_cost_address_id_address'],
        tableProperties: {
          header: 'hit-app.common.supplier',
        },
      }),
      totalAmount: new HitContainerAttribute(ATTRIBUTE_TYPES.NUMBER, {
        column: 'cost_data(amount.sum())',
        transform: (item) =>
          Array.isArray(item) && item[0] && item[0].sum ? item[0].sum : 0,
        tableProperties: {
          header: 'hit-app.common.total',
          maxWidth: '1fr',
        },
      }),
      projectPart: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_PROJECT_PART, {
        column: 'project_part_id',
        joinPath: ['project_part!fk_cost_project_part_id_project_part'],
      }),
      equipment: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_EQUIPMENT, {
        column: 'equipment_id',
        joinPath: ['equipment!fk_cost_equipment_id_equipment'],
      }),
      staff: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_STAFF, {
        column: 'staff_id',
        joinPath: ['staff!fk_cost_staff_id_staff'],
      }),
      form: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_FORM, {
        column: 'form_id',
        joinPath: ['form_view!fk_cost_form_id_form'],
      }),
      injectedFromForm: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        readOnly: true,
        column: 'form_id',
        transform: (item) => Boolean(item),
      }),
    };
  }
}
